import AuthService from "./msalAuthService";
// import GraphService from "./msalGraphService";

export const authService = new AuthService();

// graph service currently unused, but leave it here
// export const graphService = new GraphService();

// export const callAPI = () => {
//   authService.getToken().then(
//     token => {
//       graphService.getUserInfo(token).then(
//         data => {
//           // userinfo
//           console.log("data", data);
//         },
//         error => {
//           console.error(error);
//         }
//       );
//     },
//     error => {
//       console.error(error);
//     }
//   );
// };

export const acquireToken = () => {
  return authService.getToken().then(
    (token) => {
      return token;
    },
    (error) => {
      // console.error("acquireToken error", error);
      return null;
    }
  );
};

export const getUser = () => {
  return authService.getUser();
};

export const logout = () => {
  authService.logout();
};

export const login = () => {
  authService.isLoginInProgress && authService.login();
};

export const loginOrRenewToken = () => {
  if (isLoggedIn() === true) {
    renewToken();
  } else {
    login();
  }
};

export const isLoggedIn = () => {
  return authService.isLoggedIn();
};

export const renewToken = () => {
  return authService.renewToken();
};
