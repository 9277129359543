// keys
export const KEY_INVALID_AUTHENTICATION_TOKEN = "INVALID_AUTHENTICATION_TOKEN";

// codes
export const CODE_UNAUTHORIZED = 401;
export const CODE_FORBIDDEN = 403;
export const CODE_SERVER_ERROR = 500;

// code detected errors
export const NO_FILES = "You did not select valid files";
export const INVALID_SAS_URL = "INVALID_SAS_URL";
export const CONFLICTING_SUBMIT_DETECTED = "CONFLICTING_SUBMIT_DETECTED";
export const FORBIDDEN_SUBMIT_DETECTED = "FORBIDDEN_SUBMIT_DETECTED";
