import gql from "graphql-tag";

export const GetProjects = gql`
  query (
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $skip: Int
    $take: Int
  ) {
    projects(filter: $filter, sort: $sort, skip: $skip, take: $take) {
      id
      key
      name
    }
  }
`;
