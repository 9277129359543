import gql from "graphql-tag";

export const GenerateAzureStorageSASUrl = gql`
  query ($projectId: ID!, $caseName: String!, $fileName: String!) {
    phdWinConsole_generateBlobUploadUrl(
      projectId: $projectId
      caseName: $caseName
      fileName: $fileName
    )
  }
`;
