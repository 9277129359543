import gql from "graphql-tag";

export const GetCaseSubmissions_short = gql`
  query ($filter: PhdWinConsoleSubmissionFilter, $take: Int) {
    phdWinConsoleSubmissions(filter: $filter, take: $take) {
      id
      validationStatus
      processingStatus
    }
  }
`;
