import * as Msal from "msal";

// redux store
import { store } from "../App";

import { MSAL_IDTOKEN_KEY } from "../utils/constants";

import { AzureTokenSuccess, AzureTokenFailure } from "../actions/authActions";

const setNewToken = (token) => store.dispatch(AzureTokenSuccess(token));
const tokenFailure = (err) => store.dispatch(AzureTokenFailure(err));

export default class AuthService {
  constructor() {
    this.applicationConfig = {
      clientID: process.env.REACT_APP_AD_CLIENT_ID,
      authority:
        "https://login.microsoftonline.com/" +
        process.env.REACT_APP_AD_AUTHORITY_ID,
      graphScopes: ["user.read"],
      // redirectUri: "http://localhost:3000/login",
      // postLogoutRedirectUri: "http://localhost:3000",
      redirectUri: window.location.origin + "/login",
      postLogoutRedirectUri: window.location.origin,
      cacheLocation: "localStorage",
    };
    this.app = new Msal.UserAgentApplication(
      this.applicationConfig.clientID,
      this.applicationConfig.authority,
      () => {
        // callback for login redirect
      },
      {
        redirectUri: this.applicationConfig.redirectUri,
        postLogoutRedirectUri: this.applicationConfig.postLogoutRedirectUri,
        cacheLocation: this.applicationConfig.cacheLocation,
      }
    );
  }
  login = () => {
    this.app.loginPopup(this.applicationConfig.graphScopes).then(
      (idToken) => {
        setNewToken(idToken);
      },
      (err) => {
        tokenFailure(err);
      }
    );
  };
  isLoginInProgress = () => {
    return this.app.loginInProgress();
  };
  getUser = () => {
    return this.app.getUser() || null;
  };
  logout = () => {
    this.app.logout();
  };
  renewToken = () => {
    this.app.acquireTokenSilent([this.applicationConfig.clientID]).then(
      (idToken) => {
        setNewToken(idToken);
      },
      (error) => {
        this.app.acquireTokenPopup([this.applicationConfig.clientID]).then(
          (idToken) => {
            setNewToken(idToken);
          },
          (err) => {
            tokenFailure(err);
          }
        );
      }
    );
  };
  getToken = () => {
    return this.app.acquireTokenSilent(this.applicationConfig.graphScopes).then(
      (accessToken) => {
        return accessToken;
      },
      (error) => {
        return this.app
          .acquireTokenPopup(this.applicationConfig.graphScopes)
          .then(
            (accessToken) => {
              return accessToken;
            },
            (err) => {
              // console.error(err);
              return null;
            }
          );
      }
    );
  };
  isLoggedIn = () => {
    const potentialLoggedInUser = this.app.getUser();
    if (potentialLoggedInUser) {
      const idToken = this.getCacheItem(MSAL_IDTOKEN_KEY);
      const oldIDToken = potentialLoggedInUser.idToken;
      if (oldIDToken && oldIDToken.exp && idToken) {
        const expirationInMs = oldIDToken.exp * 1000; // AD returns in seconds
        if (Date.now() < expirationInMs) {
          // id token isn't expired
          return true;
        }
      }
    }
    return false;
  };
  getCacheItem = (itemKey) => {
    return localStorage.getItem(itemKey);
  };
}
