import * as actions from "../actions/actionTypes";
import { put, call, select } from "redux-saga/effects";

import {
  BlockBlobURL,
  uploadBrowserDataToBlockBlob,
  AnonymousCredential,
  StorageURL,
  Aborter,
} from "@azure/storage-blob";

import client from "../config/apolloConfig";

import { DummyQuery } from "../graphql/queries/DummyQuery";
import { GetCases } from "../graphql/queries/GetCases";
import { GetProjects } from "../graphql/queries/GetProjects";
import { GetCaseSubmission } from "../graphql/queries/GetCaseSubmission";
import { GetCaseSubmissions } from "../graphql/queries/GetCaseSubmissions";
import { GetCaseSubmissions_short } from "../graphql/queries/GetCaseSubmissions_short";
import { GenerateAzureStorageSASUrl } from "../graphql/queries/GenerateAzureStorageSASUrl";

import { CreateCaseSubmissionMutation } from "../graphql/mutations/CreateCaseSubmission";

import {
  DEFAULT_AUTOCOMPLETE_FETCH_LIMIT,
  ROUTE_TO_DASHBOARD_ERROR_PAGE,
  UNABLE_TO_FIND_CASESUBMISSION,
  DEFAULT_PAGES_COUNT,
  ROUTE_TO_DASHBOARD,
  STATUS_SUCCESS,
  STATUS_IN_PROGRESS,
  STATUS_QUEUED,
} from "../utils/constants";
import {
  NO_FILES,
  INVALID_SAS_URL,
  CONFLICTING_SUBMIT_DETECTED,
  FORBIDDEN_SUBMIT_DETECTED,
} from "../utils/errorConstants";

import { getNestedObject, getColumnSortId } from "../utils/functions";
import {
  throttledFileProgress,
  errorHandlerSaga,
  handleSnackbar,
  handleRedirect,
} from "../utils/sagaHelperFunctions";

export function* fetchDummyQuerySaga(action) {
  try {
    yield call(() =>
      client.query({
        query: DummyQuery,
        fetchPolicy: "no-cache",
      })
    );
  } catch (err) {
    yield errorHandlerSaga(err);
  }
}

export function* fetchProjectsSaga(action) {
  try {
    const result = yield call(() =>
      client.query({
        query: GetProjects,
        fetchPolicy: "no-cache",
        variables: {
          /* show projects for given search string */
          filter: {
            ...(action.payload.value && {
              keyOrNameContains: action.payload.value,
            }),
          },
          sort: [],
          take: DEFAULT_AUTOCOMPLETE_FETCH_LIMIT,
        },
      })
    );

    const data = getNestedObject(result, ["data", "projects"]) || null;

    yield put({
      type: actions.SEARCH_FOR_PROJECTS_SAGA_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    yield errorHandlerSaga(err);

    yield put({ type: actions.SEARCH_FOR_PROJECTS_SAGA_FAILURE, paload: null });
  }
}

export function* fetchCasesSaga(action) {
  try {
    const result = yield call(() =>
      client.query({
        query: GetCases,
        fetchPolicy: "no-cache",
        variables: {
          ...(action.payload.value && {
            filter: { nameContains: action.payload.value },
          }),
          take: DEFAULT_AUTOCOMPLETE_FETCH_LIMIT,
        },
      })
    );

    const data =
      getNestedObject(result, ["data", "phdWinConsoleCases"]) || null;

    yield put({
      type: actions.SEARCH_FOR_CASES_SAGA_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    yield errorHandlerSaga(err);

    yield put({ type: actions.SEARCH_FOR_CASES_SAGA_FAILURE, paload: null });
  }
}

export function* fetchCaseSubmissionSaga(action) {
  try {
    const result = yield call(() =>
      client.query({
        query: GetCaseSubmission,
        variables: { id: action.payload.id },
      })
    );

    const data =
      getNestedObject(result, ["data", "phdWinConsoleSubmission"]) || null;

    if (!data) {
      throw UNABLE_TO_FIND_CASESUBMISSION;
    }

    yield put({
      type: actions.FETCH_CASESUBMISSION_SAGA_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    yield handleRedirect(true, ROUTE_TO_DASHBOARD_ERROR_PAGE);

    yield put({
      type: actions.FETCH_CASESUBMISSION_SAGA_FAILURE,
      payload: null,
    });
  }
}

export function* fetchCaseSubmissionsSaga(action) {
  try {
    let where = {};
    getNestedObject(action, ["payload", "filtered"]).forEach((filter) => {
      switch (filter.id) {
        case "projectAsOfDate":
        case "createdAt":
        case "validationStatus":
        case "processingStatus":
          if (filter.value) {
            where = { ...where, [filter.id]: filter.value };
          }
          break;
        default:
          where = {
            ...where,
            [filter.id + "Contains"]: filter.value.toLowerCase(),
          };
      }
    });

    const sorted = getNestedObject(action, ["payload", "sorted", 0]);
    let sort = [];
    if (sorted) {
      const column = getColumnSortId(sorted.id);
      if (column) {
        sort.push({ column, order: sorted.desc ? "DESC" : "ASC" });
      }
    } else {
      sort.push({ column: getColumnSortId("id"), order: "DESC" });
    }

    const variables = {
      filter: where,
      sort: sort,
      skip: action.payload.page * action.payload.pageSize,
      take: action.payload.pageSize,
    };

    const result = yield call(() =>
      client.query({
        query: GetCaseSubmissions,
        variables,
        fetchPolicy: "no-cache",
      })
    );

    const caseSubmissions =
      getNestedObject(result, ["data", "phdWinConsoleSubmissions"]) || [];

    const count = getNestedObject(result, [
      "data",
      "phdWinConsoleSubmissionCount",
    ]);

    const caseSubmissionsPageCount = count
      ? Math.ceil(count / action.payload.pageSize)
      : DEFAULT_PAGES_COUNT;

    yield put({
      type: actions.FETCH_CASESUBMISSIONS_SAGA_SUCCESS,
      payload: { data: caseSubmissions, caseSubmissionsPageCount },
    });
  } catch (err) {
    yield errorHandlerSaga(err);

    yield put({
      type: actions.FETCH_CASESUBMISSIONS_SAGA_FAILURE,
      payload: null,
    });
  }
}

/**
 *
 * OVERWRITE CHECK SAGA
 * automatically starts upload & submit if no conflict detected
 *
 */
export function* checkForOverwrite(action) {
  try {
    const caseSubmissionForm = {
      ...(yield select((state) => state.caseSubmission.form)),
    };

    let result = null;

    /*
     * if both these fields are missing, it means the case name doesn't yet exist
     * and needs to be created, in which case we don't need to check for overwrites
     */
    if (
      getNestedObject(caseSubmissionForm, ["project", "id"]) &&
      getNestedObject(caseSubmissionForm, ["case", "id"])
    ) {
      // getCaseSubmission ordering is by latest (id desc) by default
      result = yield call(() =>
        client.query({
          query: GetCaseSubmissions_short,
          variables: {
            filter: {
              projectId: getNestedObject(caseSubmissionForm, ["project", "id"]),
              caseId: getNestedObject(caseSubmissionForm, ["case", "id"]),
            },
            take: 1,
          },
          fetchPolicy: "no-cache",
        })
      );
    }

    const lastProcessingStatus =
      getNestedObject(result, [
        "data",
        "phdWinConsoleSubmissions",
        0,
        "processingStatus",
      ]) || null;

    /**
     * Dialog rules depending on last submission processing status:
     * if it's SUCCESS => overwrite dialog prompt for confirmation
     * if it's QUEUED or IN_PROGRESS => forbidden dialog (no submit option)
     * if it FAILED or didn't exist => allow and start submit automatically
     */

    if (lastProcessingStatus === STATUS_SUCCESS) {
      throw CONFLICTING_SUBMIT_DETECTED;
    }

    if (
      lastProcessingStatus === STATUS_QUEUED ||
      lastProcessingStatus === STATUS_IN_PROGRESS
    ) {
      throw FORBIDDEN_SUBMIT_DETECTED;
    }

    // if error wasn't detected (or thrown) it was successful
    yield put({
      type: actions.CHECK_FOR_OVERWRITE_OR_FORBIDDEN_SAGA_SUCCESS,
      payload: null,
    });

    // no conflicts found, start submittal process automatically
    yield put({
      type: actions.START_UPLOAD_AND_SUBMIT_SAGA,
      payload: null,
    });
  } catch (err) {
    yield errorHandlerSaga(err);

    yield put({
      type: actions.CHECK_FOR_OVERWRITE_OR_FORBIDDEN_SAGA_FAILURE,
      payload: { err },
    });
  }
}

/*
 *
 * SUBMIT SAGA
 *
 */
export function* uploadFilesAndSubmitFormSaga(action) {
  try {
    /**
     *
     * START FILE UPLOAD PROCESS
     *
     */

    let sasURL = null;
    let generateSASUrlResult = null;

    let pipeline = null;
    let blockBlobURL = null;

    let urlParts = null;
    let blobContainer = null;
    let blobName = null;

    let caseSubmissionForm = {
      ...(yield select((state) => state.caseSubmission.form)),
    };

    const files = getNestedObject(caseSubmissionForm, ["files"]) || null;

    const filesDataToSubmit = [];

    if (!files) {
      throw NO_FILES;
    }

    let currentIndex = 0;
    for (const file of files) {
      // generate url for a new file
      // eslint-disable-next-line no-loop-func
      generateSASUrlResult = yield call(() =>
        client.query({
          query: GenerateAzureStorageSASUrl,
          variables: {
            projectId: caseSubmissionForm.project.id,
            caseName: getNestedObject(caseSubmissionForm, ["case", "name"]),
            fileName: file.blobObject.name,
          },
          fetchPolicy: "no-cache",
        })
      );

      sasURL =
        getNestedObject(generateSASUrlResult, [
          "data",
          "phdWinConsole_generateBlobUploadUrl",
        ]) || null;

      if (!sasURL) {
        throw INVALID_SAS_URL;
      }

      // extract container and blob name from url
      urlParts = /(https:\/\/.*?)\/(.*?)\/(.*?)\?(.*)/.exec(sasURL);
      blobContainer = urlParts[2];
      blobName = urlParts[3];

      // https://github.com/Azure/azure-storage-node/issues/534
      pipeline = StorageURL.newPipeline(new AnonymousCredential());

      blockBlobURL = new BlockBlobURL(sasURL, pipeline);

      // upload file
      yield uploadBrowserDataToBlockBlob(
        Aborter.none,
        file.blobObject,
        blockBlobURL,
        {
          // eslint-disable-next-line no-loop-func
          progress: (ev) => {
            const progress = ev.loadedBytes / file.blobObject.size;

            throttledFileProgress(blobContainer, blobName, progress);
          },
        }
      );

      filesDataToSubmit.push({
        sequenceElement: currentIndex,
        originalFileName: file.blobObject.name,
        fileType: file.blobObject.type,
        fileSize: file.blobObject.size,
        blobContainer,
        blobName,
      });

      // increase file counter
      currentIndex = currentIndex + 1;

      /* we cannot rely that uploads' progress callback will
       * correctly send last expected action
       * so send one manually
       */
      yield put({
        type: actions.UPLOAD_FILE_DONE,
        payload: null,
      });
    }

    yield throttledFileProgress.flush();

    /**
     *
     * START CASE SUBMISSION... SUBMISSION
     *
     */
    yield call(() =>
      client.mutate({
        mutation: CreateCaseSubmissionMutation,
        variables: {
          projectId: caseSubmissionForm.project.id,
          caseName: caseSubmissionForm.case.name,
          originalFileName: filesDataToSubmit[0].originalFileName,
          blobContainer: filesDataToSubmit[0].blobContainer,
          blobName: filesDataToSubmit[0].blobName,
          blobSize: filesDataToSubmit[0].fileSize,
          mimeType: filesDataToSubmit[0].fileType,
        },
      })
    );

    // dispatch action on all uploads finished
    yield put({
      type: actions.START_UPLOAD_AND_SUBMIT_SAGA_SUCCESS,
      payload: null,
    });

    yield handleSnackbar(true, "Case submission success!");
    yield handleRedirect(true, ROUTE_TO_DASHBOARD);
  } catch (err) {
    yield errorHandlerSaga(err);

    yield put({
      type: actions.START_UPLOAD_AND_SUBMIT_SAGA_FAILURE,
      payload: null,
    });
  }
}
