import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import AuthReducer from "./reducer_auth";
import GlobalReducer from "./reducer_global";
import CaseSubmissionReducer from "./reducer_casesubmission";

const rootReducer = (history) =>
  combineReducers({
    auth: AuthReducer,
    global: GlobalReducer,
    caseSubmission: CaseSubmissionReducer,
    router: connectRouter(history),
  });

export default rootReducer;
