import gql from "graphql-tag";

export const GetCaseSubmission = gql`
  query ($id: ID!) {
    phdWinConsoleSubmission(id: $id) {
      id
      validationStatus
      processingStatus
      statusDescription
      etlBatchId
      startedAt
      endedAt
      originalFileName
      blobSize
      case {
        id
        name
      }
      project {
        id
        key
        name
        asOfDate
      }
      user {
        id
        oid
        name
        email
      }
      details {
        id
        stage
        name
        status
        description
      }
    }
  }
`;
