import gql from "graphql-tag";

export const CreateCaseSubmissionMutation = gql`
  mutation (
    $projectId: ID!
    $caseName: String!
    $originalFileName: String!
    $blobContainer: String!
    $blobName: String!
    $blobSize: Float!
    $mimeType: String!
  ) {
    phdWinConsoleSubmit(
      projectId: $projectId
      caseName: $caseName
      originalFileName: $originalFileName
      blobContainer: $blobContainer
      blobName: $blobName
      blobSize: $blobSize
      mimeType: $mimeType
    ) {
      id
    }
  }
`;
