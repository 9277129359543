import gql from "graphql-tag";

export const GetCaseSubmissions = gql`
  query (
    $filter: PhdWinConsoleSubmissionFilter
    $sort: [PhdWinConsoleSubmissionSort!]
    $skip: Int
    $take: Int
  ) {
    phdWinConsoleSubmissionCount(filter: $filter)
    phdWinConsoleSubmissions(
      filter: $filter
      sort: $sort
      skip: $skip
      take: $take
    ) {
      id
      validationStatus
      processingStatus
      createdAt
      case {
        name
      }
      project {
        key
        name
        asOfDate
      }
      user {
        name
      }
    }
  }
`;
