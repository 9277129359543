import * as actions from "../actions/actionTypes";
import { takeEvery } from "redux-saga/effects";

import {
  fetchDummyQuerySaga,
  fetchProjectsSaga,
  fetchCaseSubmissionSaga,
  fetchCaseSubmissionsSaga,
  uploadFilesAndSubmitFormSaga,
  fetchCasesSaga,
  checkForOverwrite,
} from "./caseSubmissionSagas";

export function* watchAll() {
  yield takeEvery(actions.FETCH_DUMMY_QUERY_SAGA, fetchDummyQuerySaga);
  yield takeEvery(actions.SEARCH_FOR_PROJECTS_SAGA, fetchProjectsSaga);
  yield takeEvery(actions.SEARCH_FOR_CASES_SAGA, fetchCasesSaga);
  yield takeEvery(actions.FETCH_CASESUBMISSION_SAGA, fetchCaseSubmissionSaga);
  yield takeEvery(actions.FETCH_CASESUBMISSIONS_SAGA, fetchCaseSubmissionsSaga);
  yield takeEvery(
    actions.CHECK_FOR_OVERWRITE_OR_FORBIDDEN_SAGA,
    checkForOverwrite
  );
  yield takeEvery(
    actions.START_UPLOAD_AND_SUBMIT_SAGA,
    uploadFilesAndSubmitFormSaga
  );
}
