import { filterTableColumns } from "./storeFunctions";
import tableColumns from "../components/views/userDashboard/caseSubmissionOverview/tableColumns";

/* GLOBAL STATES */
export const initialGlobalState = {
  isSnackbarOpen: false,
  snackbarMessage: "",
  fixedHeader: true,
};

/* AUTH STATES */
export const initialAuthState = {
  user: {},
  accessLevel: 0,
  token: null,
  isErr: false,
  err: null,
  isAuthenticated: false,
  requestedPage: null,
  isInitialRequestedPageStored: false,
};

export const defaultCaseSubmissionFormState = () => ({
  project: null,
  case: null,
  files: [],
});

export const defaultFormErrorsState = () => ({});

const defaultColumnsToShow = [
  "view",
  "id",
  "projectKey",
  "projectName",
  "projectAsOfDate",
  "createdAt",
  "caseName",
  // "fileCount",
  "validationStatus",
  "processingStatus",
  "userName",
];

export const initialCaseSubmissionState = {
  // submit in progress related
  submitInProgress: false,
  queuedFileUploads: 0,
  currentFileUploadProgress: 0,
  // dashboard table
  tableData: [],
  columns: filterTableColumns(tableColumns, defaultColumnsToShow),
  columnsToShow: defaultColumnsToShow,
  loadingTableData: false,
  caseSubmissionsPageCount: null,
  // project autocomplete
  projectAutocompleteOptions: [],
  isLoadingProjects: false,
  // case autocomplete
  caseAutocompleteOptions: [],
  isLoadingCases: false,
  // form
  loadingForm: true,
  checkingOverwriteOrForbidden: false,
  overwriteDialogOpen: false,
  submitForbiddenDialogOpen: false,
  formErrors: defaultFormErrorsState(),
  form: defaultCaseSubmissionFormState(),
};
