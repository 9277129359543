import gql from "graphql-tag";

export const GetCases = gql`
  query ($filter: PhdWinConsoleCaseFilter, $take: Int) {
    phdWinConsoleCases(filter: $filter, take: $take) {
      id
      name
    }
  }
`;
